import { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../Login/AuthContext';
import Notification from '../Notification/NotificationMessage';

const useProfilePageHook = () => {
  const [profile, setProfile] = useState({ name: '', email: '', isAdmin: false });
  const [usage, setUsage] = useState({});
  const [genericCredit, setGenericCredit] = useState(0);
  const [generationCredit, setGenerationCredit] = useState(0);
  const [qualificationCredit, setQualificationCredit] = useState(0);

  const fetchUserProfile = async () => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}user/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      if (response.data) {
        console.log(response.data)
        setProfile({
          name: response.data.user_profile.username,
          email: response.data.user_profile.email,
          isAdmin: response.data.user_profile.is_admin
        });
        setGenericCredit(response.data.user_profile.credits.generic_credit);
        setGenerationCredit(response.data.user_profile.credits.generation_credit);
        setQualificationCredit(response.data.user_profile.credits.qualification_credit);
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching profile:", error);
      Notification.error('Error fetching profile');
      return null;
    }
  };

  const fetchResearchSessions = async (email) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}view_user_session/`, {
        headers: {
          'Authorization': `Token ${token}`  // Ensure the token prefix is correct; it might be 'Bearer' depending on your backend setup
        },
        params: { email }  // Correctly passing 'email' as a query parameter
      });
  
      if (response.status === 200) {
        console.log("Session data:", response.data); // Successful fetch
        return response.data;
      } else {
        throw new Error(`Failed to fetch: ${response.status}`); // Handle non-200 responses
      }
    } catch (error) {
      console.error("Error fetching session:", error);
      Notification.error(`Error fetching session: ${error.message || 'Unknown error'}`);
      return null;
    }
  };
   

  const addAllowedUser = async (email) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}add_allowed_user/`, 
        { email },
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      );

      Notification.complete(response.data.status);
      return response.data;
    } catch (error) {
      console.error("Error adding allowed user:", error);
      Notification.error('Error adding allowed user');
      return null;
    }
  };

  const addUserTag = async (email, tagName) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}add_user_tag/`, 
        { email, tag_name: tagName },
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      );
  
      Notification.complete(response.data.status);
      return response.data;
    } catch (error) {
      console.error("Error adding user tag:", error);
      Notification.error('Error adding user tag');
      return null;
    }
  };  

  const setUserCredits = async (email, credits) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}set_user_credits/`, 
        { email, ...credits },
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      );

      Notification.complete(response.data.status);
      return response.data;
    } catch (error) {
      console.error("Error setting user credits:", error);
      Notification.error('Error setting user credits');
      return null;
    }
  };

  const setPremiumUser = async (email, isPremium) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}set_user_premium_status/`, 
        { 'email':email, 'is_premium':isPremium },
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      );

      Notification.complete(response.data.status);
      return response.data;
    } catch (error) {
      console.error("Error setting user credits:", error);
      Notification.error('Error setting user credits');
      return null;
    }
  };  

  const cleanupSessions = async (email, sessionThreshold) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}cleanup_sessions/`, 
        { 'email': email, 'session_id_threshold': sessionThreshold },
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      );

      Notification.complete(response.data.status);
      return response.data;
    } catch (error) {
      console.error("Error setting user credits:", error);
      Notification.error('Error setting user credits');
      return null;
    }
  };  

  const retryFailedJobs = async (email, session_id) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}retry_failed_jobs/`, {email, session_id}, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      if (response.status === 200) {
        Notification.complete('Failed jobs retried successfully');
      } else {
        Notification.error('Failed to retry jobs');
      }
    } catch (error) {
      console.error("Error retrying failed jobs:", error);
      Notification.error('Error retrying failed jobs');
    }
  };  
  const exportCampaign = async () => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}export_campaign/`, {
        headers: {
          'Authorization': `Token ${token}`
        },
      });

      if (response.status === 200) {
        console.log(response)
        const { data, filename } = response.data;
  
        // Create a Blob from the data
        const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
  
        // Create a link element to download the file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Use the filename from the response
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        Notification.error('Failed to export business context');
      }
    } catch (error) {
      console.error("Error exporting campaign:", error);
      Notification.error('Error exporting campaign');
    }
  };

  const importCampaign = async (email, file) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }
  
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const content = event.target.result;
  
        const payload = {
          email,
          file_content: content
        };
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}import_campaign/`, payload, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          }
        });
  
        if (response.status === 201 || response.status === 200) {
          Notification.complete('Campaign imported successfully');
        } else {
          Notification.error('Failed to import campaign');
        }
      };
      reader.readAsText(file);
    } catch (error) {
      console.error("Error importing campaign:", error);
      Notification.error('Error importing campaign');
    }
  };

  return { profile, usage, fetchUserProfile, fetchResearchSessions, genericCredit, generationCredit, qualificationCredit, addAllowedUser, setUserCredits, setPremiumUser, retryFailedJobs, exportCampaign, importCampaign, cleanupSessions, addUserTag };
};

export default useProfilePageHook;

