import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Card, Switch, Spin, Modal, Table } from 'antd';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

const AdminPage = () => {
  const { 
    cleanupSessions, 
    addAllowedUser, 
    setUserCredits, 
    profile, 
    retryFailedJobs, 
    exportCampaign, 
    importCampaign, 
    setPremiumUser, 
    fetchUserProfile,
    fetchResearchSessions,
    addUserTag
  } = useProfilePageHook();
  
  const [email, setEmail] = useState('');
  const [tagName, setTagName] = useState('');

  const [credits, setCredits] = useState({ generic_credit: 0, qualification_credit: 0, generation_credit: 0 });
  const [sessionThreshold, setSessionThreshold] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);  

  useEffect(() => {
    const loadProfile = async () => {
      await fetchUserProfile();
      setLoading(false);
    };
    loadProfile();
  }, []);  

  if (loading) {
    return <Spin tip="Loading profile..." />;
  }

  const handleAddAllowedUser = () => {
    addAllowedUser(email);
  };

  const handleSetUserCredits = () => {
    setUserCredits(email, credits);
  };

  const handleAddUserTag = () => {
    addUserTag(email, tagName);
  };  

  const handleRetryFailedJobs = () => {
    retryFailedJobs(email, sessionThreshold);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImportCampaign = () => {
    if (selectedFile) {
      importCampaign(email, selectedFile);
    } else {
      console.error("No file selected!");
    }
  };

  const handleViewUserSessions = async () => {
    const sessionsData = await fetchResearchSessions(email);
    setSessions(sessionsData);
    setIsModalVisible(true);
  };

  const columns = [
    { title: 'Session ID', dataIndex: 'session_id', key: 'session_id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Upload Date', dataIndex: 'upload_date', key: 'upload_date' },
    { title: 'State', dataIndex: 'session_state', key: 'session_state' },
  ];
  
  // State descriptions based on the enum values from the backend
  const getStateDescription = (state) => {
    const stateDescriptions = {
      0: 'Default',
      1: 'Processing',
      2: 'Complete',
      3: 'Fail',
      4: 'Contact Not Found',
      5: 'Email Not Found',
      6: 'Profile Incomplete',
      7: 'Website Error',
      8: 'Reachout Error',
      9: 'Reachout Complete',
      10: 'Out of Credit'
    };
    return stateDescriptions[state] || 'Unknown State';
  };

  const expandedRowRender = (record) => {
    const urlColumns = [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: state => getStateDescription(state),
        sorter: (a, b) => a.state - b.state,  // Enable sorting based on the state integer
      },
      { title: 'URL ID', dataIndex: 'url_id', key: 'url_id' },
      { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
      { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
      {
        title: 'Email', 
        dataIndex: 'contact_email', 
        key: 'contact_email', 
        render: email => (
          <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {email}
          </div>
        )
      },
      {
        title: 'LinkedIn', 
        dataIndex: 'linkedin', 
        key: 'linkedin', 
        render: linkedin => (
          <a href={linkedin} target="_blank" rel="noopener noreferrer" style={{ maxWidth: '150px', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {linkedin}
          </a>
        )
      },
      {
        title: 'Company Domain', 
        dataIndex: 'company_domain', 
        key: 'company_domain',
        render: domain => (
          <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {domain}
          </div>
        )
      },
      { title: 'Description', dataIndex: 'state_details', key: 'state_details' }
    ];
  
    return (
      <Table 
        columns={urlColumns} 
        dataSource={record.urls} 
        pagination={{ pageSize: 20 }}  // Set pagination with 20 rows per page
        rowKey="url_id"
      />
    );
  };

  

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  if (loading) {
    return <Spin tip="Loading profile..." />;
  }

  const handleExportCampaign = () => {
    exportCampaign();
  };

  const handleCleanupSessions = () => {
    cleanupSessions(email, sessionThreshold);
  };

  const handleSetPremiumUser = () => {
    setPremiumUser(email, isPremium);
  };

  return (
    <div style={{ padding: '20px' }}>
      {profile.isAdmin ? (
        <Card title="Admin Page" bordered={false}>
          <Form layout="vertical">
            <Form.Item label="Email">
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              <Button type="primary" onClick={handleViewUserSessions}>View User Sessions</Button>              
            </Form.Item>

            <Form.Item label="Custom Tag Name">
              <Input value={tagName} onChange={(e) => setTagName(e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleAddUserTag}>Toggle User Tag</Button>
              <p>Options are: "NEED_COMPANY_ORG_ENRICHMENT_V1", "NEED_CONTACT_WEB_ENRICHMENT_V1", "NEED_METAPHOR_ENRICHMENT_V1"</p>
            </Form.Item>            

            <Form.Item label="Session Id Threshold">
              <Input type="number" value={sessionThreshold} onChange={(e) => setSessionThreshold(e.target.value)} />
            </Form.Item>
            

            <Form.Item>
              <Button type="primary" onClick={handleCleanupSessions}>Cleanup Sessions</Button>
            </Form.Item>

            <Form.Item label="Generic Credit">
              <Input type="number" value={credits.generic_credit} onChange={(e) => setCredits({ ...credits, generic_credit: e.target.value })} />
            </Form.Item>

            <Form.Item label="Qualification Credit">
              <Input type="number" value={credits.qualification_credit} onChange={(e) => setCredits({ ...credits, qualification_credit: e.target.value })} />
            </Form.Item>

            <Form.Item label="Generation Credit">
              <Input type="number" value={credits.generation_credit} onChange={(e) => setCredits({ ...credits, generation_credit: e.target.value })} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleAddAllowedUser}>Add Allowed User</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleSetUserCredits}>Set User Credits</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleRetryFailedJobs}>Retry Failed Jobs</Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={handleExportCampaign}>Export Campaign</Button>
            </Form.Item>

            <Form.Item label="Import Campaign">
              <input type="file" onChange={handleFileChange} />
              <Button type="primary" onClick={handleImportCampaign}>Import Campaign</Button>
            </Form.Item>

            <Form.Item label="Set Premium User">
              <Switch checked={isPremium} onChange={setIsPremium} />
              <Button type="primary" onClick={handleSetPremiumUser} style={{ marginLeft: '10px' }}>
                Set Premium User
              </Button>
            </Form.Item>
          </Form>

          <Modal
            title="Research Sessions"
            visible={isModalVisible}
            onCancel={handleModalClose}
            width={1500}
            footer={[
              <Button key="close" onClick={handleModalClose}>
                Close
              </Button>
            ]}
          >
            <Table 
              columns={columns} 
              dataSource={sessions} 
              rowKey="session_id"
              expandedRowRender={expandedRowRender}
            />

          </Modal>
        </Card>
      ) : (
        <p>You do not have permission to access this page.</p>
      )}
    </div>
  );
};

export default AdminPage;
